
button{
    color:red;
    background-color:#e0eff9;
    color:#1976d2;
    border: none;
    margin-left: 10px;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
}