body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media(max-width:810px) {
    .slider1 {
        height: 32vh
    }
    .loginimg1 {
        width: 70%
    }
    .sizer {
        font-size: 20;
    }
    .side1 {
        display: none
    }
    .side2 {
        background-color: white;
        text-align: center;
        color: #767676;
    }
    .logomain {
        width: 25%;
    }
    .slider {
        margin-top: 20%;
        color: white
    }
    .credentials {
        width: 300px;
    }
    .credentials2 {
        width: 270px;
    }
    .signup {
        width: 300px
    }
}

@media(min-width:810px) {
    .loginbox {
        width: 80%;
        margin-left: 10%;
    }
    .slider1 {
        height: 60vh
    }
    .sizer {
        font-size: 30px;
    }
    .loginimg1 {
        width: 70%
    }
    .slogan {
        display: none
    }
    .mainsection {
        position: fixed;
        height: 100%;
        width: 100%
    }
    .mainsection>div {
        width: 50%;
        display: inline-block;
        height: 100%;
    }
    .side1 {
        background-color: #1976d2;
    }
    .side2 {
        background-color: white;
        text-align: center;
        color: #767676
    }
    .logomain {
        width: 15%;
    }
    .slider {
        margin-top: 20%;
        color: white
    }
    .credentials {
        width: 320px;
    }
    .credentials2 {
        width: 290px;
    }
    .signup {
        width: 260px
    }
}

a {
    text-decoration: none
}

Link {
    text-decoration: none
}

Button {
    background-color: #1976d2
}

.menuclick:hover {
    background-color: red
}

.table1,
.th1,
.td1 {
    border: solid;
    border-width: 0.5px;
    padding: 8px;
    margin: 0px;
    border-color: rgba(0, 0, 56, 0.1);
}

.menulink:hover {
    background-color: #e0eff9;
    color: white;
}

.menulinkclick {
    background-color: #e0eff9;
    color: #1976d2;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    /* margin-right: 100px; */
    border-left: solid;
    border-color: #1976d2;
}

.scroller {
    overflow-y: scroll;
    background-color: #e0eff9;
    height: 160px;
    margin-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    color: #1976d2
}

.scroller::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
}

.scroller::-webkit-scrollbar-thumb {
    background-color: lightgray;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    /* border: 3px solid orange; */
    /* creates padding around scroll thumb */
}

.scroller2 {

    overflow-y: scroll;
    background-color: #e0eff9;
    color: #1976d2;
    overflow-x: hidden;
    
}

.scroller2::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
}

.scroller2::-webkit-scrollbar-thumb {
    background-color: lightgray;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    /* border: 3px solid orange; */
    /* creates padding around scroll thumb */
}

.scroller3 {
    overflow-y: scroll;
    color: #1976d2
}

.scroller3::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
}

.scroller3::-webkit-scrollbar-thumb {
    background-color: lightgray;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    /* border: 3px solid orange; */
    /* creates padding around scroll thumb */
}

.tablehead {
    background-color: blue;
}

input:focus {
    outline: none
}

textArea:focus {
    outline: none
}

Select:focus {
    outline: none
}

#Select {
    outline: none
}

.particle {
    position: relative;
    width: 100%;
    height: 100%;

    background-color:white;
    background-size: cover;
}
#button_{
    background-color: red;
    color: #89a7af ;
}
@media print {
    
    .pagebreak { page-break-after: always; } /* page-break-after works, as well */
}